import axios from "axios";
import {
  IRequestUserPasswordReset,
  IRequestUserProfileUpdate,
  IRequestUserConfirmPasswordReset,
} from "@/api/types/loginUser.types";

export default class LoginUserAPI {
  private static baseUrl = "/user";

  public static async RequestUserPasswordReset(
    email: IRequestUserPasswordReset,
  ) {
    return await axios.post(`${this.baseUrl}/passwordreset`, email);
  }

  public static async RequestUserProfileUpdate(
    data: IRequestUserProfileUpdate,
  ) {
    return await axios.put(`${this.baseUrl}/profile`, data);
  }

  public static async RequestUserSetNewPassword(
    body: IRequestUserConfirmPasswordReset,
  ) {
    return await axios.post(`${this.baseUrl}/passwordreset/confirmation`, body);
  }

  public static async RequestUserInfo() {
    return await axios.get(`${this.baseUrl}/me`);
  }
}
