import { Middleware } from "@reduxjs/toolkit";
import { RootState } from "..";
import { getTokenRefreshPeriod } from "@/auth/auth.utils";
import { setAuthToken } from "../slices/auth.slice";
import { requestUserLogInRefresh } from "@/app/(pages)/actions/auth";
import { logoutCookie } from "@/app/(pages)/login/actions/login";

let tokenRefreshTimeout: NodeJS.Timeout | null = null;
const AUTH_KEY = "auth";

const tokenRefreshMiddleware: Middleware<object, RootState> =
  (store) => (next) => (action) => {
    const startTokenRefresh = () => {
      const milliseconds = getTokenRefreshPeriod();

      tokenRefreshTimeout = setTimeout(async () => {
        if (store.getState().auth.isLoggedIn) {
          try {
            const response = await requestUserLogInRefresh();
            store.dispatch(setAuthToken(response));
          } catch (err) {
            logoutCookie();
            localStorage.removeItem(AUTH_KEY);
          }
        }
      }, milliseconds);
    };

    const stopTokenRefresh = () => {
      if (tokenRefreshTimeout) {
        clearTimeout(tokenRefreshTimeout);
        tokenRefreshTimeout = null;
      }
    };

    switch (action.type) {
      case "[authSlice]/loginSuccess":
      case "[authSlice]/setAuthToken":
        startTokenRefresh();
        break;

      case "[authSlice]/logout":
        stopTokenRefresh();
        break;
    }
    return next(action);
  };

export default tokenRefreshMiddleware;
