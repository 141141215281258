import axios from "axios";
import { ENV_CONSTANTS } from "../constants/env.const";
import {
  getAuthenticatedUserInfo,
  isRefreshRequired,
  updateToken,
} from "@/auth/auth";

const excludedPaths = ["auth", "user"];

export function setupAxiosInterceptor() {
  axios.interceptors.request.use(
    (config) => {
      const urlWithoutSlash = config.url?.startsWith("/")
        ? config.url?.substring(1)
        : config.url;
      const firstPart = urlWithoutSlash?.split("/")[0] ?? "";

      if (excludedPaths.includes(firstPart)) {
        return config;
      }

      if (isRefreshRequired()) {
        updateToken();

        const userInfo = getAuthenticatedUserInfo();
        const accessToken = userInfo?.accessToken ?? null;

        // set a new response header "Authorization"
        config.headers["Authorization"] = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );
}

export const setAxiosBaseUrl = () => {
  axios.defaults.baseURL = ENV_CONSTANTS.API_BASEURL;
  let token = ENV_CONSTANTS.DEFAULT_API_BEARER_TOKEN;
  if (typeof localStorage !== "undefined") {
    const auth = getAuthenticatedUserInfo();
    token = auth?.accessToken ?? token;
  }

  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export const setAxiosAccessToken = (token: string) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export const removeAxiosAccessToken = () => {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${ENV_CONSTANTS.DEFAULT_API_BEARER_TOKEN}`;
};
