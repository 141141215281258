export const ENV_CONSTANTS = {
  IS_DEV_MODE: process.env.NEXT_PUBLIC_SETTINGS === "development",
  API_BASEURL: process.env.NEXT_PUBLIC_API_BASEURL,
  MAXIMUM_ACTIVE_UPLOADS: process.env.NEXT_PUBLIC_MAXIMUM_ACTIVE_UPLOADS || "4",
  DEFAULT_API_BEARER_TOKEN: process.env.NEXT_PUBLIC_DEFAULT_API_BEARER_TOKEN,
  NEXT_PUBLIC_PEACH_TERMS_URL: process.env.NEXT_PUBLIC_PEACH_TERMS_URL,
  NEXT_PUBLIC_PEACH_FEEDBACK_URL: process.env.NEXT_PUBLIC_PEACH_FEEDBACK_URL,
  NEXT_PUBLIC_PEACH_GO_BLOG_POST: process.env.NEXT_PUBLIC_PEACH_GO_BLOG_POST,
  LOGROCKET_APP_ID: process.env.NEXT_PUBLIC_LOGROCKET_APP_ID || "",
  LOGROCKET_HASH: process.env.NEXT_PUBLIC_LOGROCKET_HASH || "",
  LOGROCKET_ENABLED: process.env.NEXT_PUBLIC_LOGROCKET_ENABLED === "true",
  IS_RAYGUN_ENABLED: process.env.NEXT_PUBLIC_IS_RAYGUN_ENABLED === "true",
  RAYGUN_HASH: process.env.NEXT_PUBLIC_RAYGUN_HASH,
  RAYGUN_API_KEY: process.env.NEXT_PUBLIC_RAYGUN_API_KEY,
  IS_SPEEDCURVE_ENABLED: process.env.NEXT_PUBLIC_SPEEDCURVE_ENABLED === "true",
  IS_HEAP_ENABLED: process.env.NEXT_PUBLIC_IS_HEAP_ENABLED === "true",
  HEAP_ID: process.env.NEXT_PUBLIC_HEAP_APP_ID,
  HEAP_HASH: process.env.NEXT_PUBLIC_HEAP_HASH,
  HEAP_INTERNAL_DOMAINS: process.env.NEXT_PUBLIC_HEAP_INTERNAL_DOMAINS || "",
  HEAP_INTERNAL_USERS: process.env.NEXT_PUBLIC_HEAP_INTERNAL_USERS || "",
  IS_NAVIGATION_ENABLED: process.env.NEXT_PUBLIC_ENABLE_NAVIGATION === "true",
  NEXT_PUBLIC_PEACH_ABOUT_URL: process.env.NEXT_PUBLIC_PEACH_ABOUT_URL,
  IS_SEO_ENABLED: process.env.NEXT_PUBLIC_SEO_ENABLED === "true",
  TOKEN_REFRESH_WINDOW_MINUTES:
    process.env.NEXT_PUBLIC_TOKEN_REFRESH_WINDOW_MINUTES || "15",
  SPEEDCURVE_INTEGRITY: process.env.NEXT_PUBLIC_SPEEDCURVE_INTEGRITY,
  IS_PROFILE_ANALYSIS_ENABLED:
    process.env.NEXT_PUBLIC_ENABLE_PROFILE_ANALYSIS === "true",
  FEATURE_ANALYSIS_TRIAL_LABEL_ENABLED:
    process.env.NEXT_PUBLIC_FEATURE_ANALYSIS_TRIAL_LABEL_ENABLED === "true",
  FEATURE_SSO_ENABLED: process.env.NEXT_PUBLIC_FEATURE_SSO_ENABLED === "true",
  NEXT_PUBLIC_PEACH_PRIVACY_URL: process.env.NEXT_PUBLIC_PEACH_PRIVACY_URL,
  IS_AB_OPTIMISATION_ENABLED:
    process.env.NEXT_PUBLIC_IS_AB_OPTIMISATION_ENABLED === "true",
};
